<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
    :mostra-botao-salvar-continuar="false"
    :mostra-botao-deletar="false"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting || readOnly"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col
          :cols="12"
          sm="6"
        >
          <caixa-select
            v-model="objEditar.idCaixa"
            :readonly="!empresaClienteModelId"
            :id-empresa-cliente="empresaClienteModelId || idEmpresaCliente"
            adiciona-valor-selecione
            :adiciona-valor-todos="false"
            @input="buscaValorAtual"
          />
        </v-col>
        <v-col
          :cols="12"
          md="4"
        >
          <v-text-field
            :value="$t('HistoricoMovimentacaoCaixaList.origemMovimentacao_'+objEditar.origemMovimentacao)"
            readonly
            :label="$t('HistoricoMovimentacaoCaixaList.origemMovimentacao')"
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="objEditar.valorMovimentado"
            :readonly="isEditting"
            :label="$t('HistoricoMovimentacaoCaixaList.valorMovimentado')"
            prepend-icon="mdi-cash-100"
            class="purple-input"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="valorAtualComputed"
            :label="$t('HistoricoMovimentacaoCaixaList.valorAtual')"
            class="purple-input"
            type="number"
            readonly
            :loading="loading"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="objEditar.motivoAjuste"
            :readonly="isEditting"
            :label="$t('HistoricoMovimentacaoCaixaList.motivoAjuste')"
            class="purple-input"
            prepend-icon="mdi-account-edit"
            counter
            outlined
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '../services/HistoricoMovimentacaoCaixaService'
  import CaixaService from '@/services/CaixaService'
  import { mapState } from 'vuex'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import EmpresaClientesService from '@/services/EmpresaClientesService'
  import CaixaSelect from '../components/CaixaSelect'

  export default {
    components: {
      EmpresaClienteAutoComplete,
      CaixaSelect
    },
    data: () => ({
      menuDataCompra: false,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      loading: false,
      readOnly: false,
      backUrl: null,
      objPadrao: {
        valorMovimentado: null,
        motivoAjuste: '',
        origemMovimentacao: 0,
        idUsuarioAlteracao: null,
        idEmpresaCliente: null,
        idCaixa: null
      },
      objEditar: {
        valorMovimentado: null,
        motivoAjuste: '',
        origemMovimentacao: 0,
        idUsuarioAlteracao: null,
        idEmpresaCliente: null,
        idCaixa: null
      },
      isEditting: true,
      valorAtual: 0
    }),
    computed: {
      valorAtualComputed () {
        return parseFloat(this.valorAtual)
      },
      required: function(value) { return !!value || this.$i18n.t('padrao_campo_obrigatorio')},
      ...mapState(['idEmpresaCliente']),
      tituloPagina () {
        return this.$i18n.t('HistoricoMovimentacaoCaixaList.titulo')
      },
    },
    watch: {
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      this.backUrl = this.$route.params.backUrl
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)

        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then((res) => {
            this.objEditar.EmpresaCliente = res.data
            this.empresaClienteModel = res.data
            this.empresaClienteModelId = this.empresaClienteModel.id
          })
        }
      }
    },
    methods: {

      buscaValorAtual () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }

        if(!this.objEditar.idCaixa){
          return 
        }

        this.loading = true

        this.valorAtual = 0

        CaixaService.findById({id: this.objEditar.idCaixa})
          .then((caixa) => {
            if (caixa && caixa.data) {
              this.valorAtual = caixa.data.saldo
            }
          })
          .catch(err => {
            console.log('errou -> ', err)
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar)
      },
      closeDialog () {
        this.$router.push(`/${this.backUrl}`)
      },
      inserir (registro) {
        try {

          registro.idUsuarioAlteracao = JSON.parse(localStorage.arshome_auth).id

          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              this.closeDialog()
            }
          })
            .catch(err => {
              console.log(`errou -> `, err)
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err.response.data.error
              })
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Despera:\n ->' + error
          })
        }
      },
      editar (id) {
        this.readOnly = true
        Servico.findById({ id })
          .then((registro) => {
            this.objEditar = registro.data
            this.isEditting = true

            this.empresaClienteModel = this.objEditar.EmpresaCliente
            this.empresaClienteModelId = this.objEditar.idEmpresaCliente
          })
          .catch(() => {
            this.isEditting = true
          })
      }
    }
  }
</script>
